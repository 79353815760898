/* eslint-disable indent */
import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import idx from 'idx'
import { RichText } from 'prismic-reactjs'
import GatsbyLink from '../../utils/GatsbyLink'
import Text from '../common/Text'
import Space from '../common/Space'
import Button from '../common/Button'
import violetCheckMarkIcon from '../../../static/icons/check-icons/violet-check-icon.svg'
import greenCheckMarkIcon from '../../../static/icons/check-icons/green-stroke-check-icon.svg'
import { theme } from '../../styles'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding-top: ${p => (p.hasTopPadding ? '80px' : '8px')};
  padding-bottom: ${p => (p.hasBottomPadding ? '80px' : '8px')};

  ul {
    list-style-position: inside;
  }
  @media (max-width: 1050px) {
    padding-top: ${p => (p.hasTopPadding ? '64px' : '8px')};
    padding-bottom: ${p => (p.hasBottomPadding ? '64px' : '8px')};
    * {
      text-align: center !important;
    }
    h2,
    h3 {
      margin: 0 auto;
    }
  }
`

const BG = styled.div`
  position: absolute;
  width: 500vw;
  height: 100%;
  z-index: -1;
  background-color: ${p => (p.backgroundColor ? p.theme.color[p.backgroundColor] : 'transparent')};
  margin-left: -100%;
  margin-top: ${p => (p.hasTopPadding ? '-80px' : '-8px')};

  @media (max-width: 1050px) {
    margin-top: ${p => (p.hasTopPadding ? '-64px' : '-8px')};
  }
`

const CenteredWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  max-width: ${p => (p.centerColTextLeftAligned ? '700px' : '900px')};

  @media (max-width: 1050px) {
    * {
      text-align: ${p => (p.centerColTextLeftAligned ? 'left!important' : 'inherit')};
    }
  }
`

const HeaderWrapper = styled.div`
  display: flex;
  margin-bottom: 24px;
  ${p =>
    p.headingOnly &&
    `
    margin-bottom: 0px;
  `}

  @media (max-width: 1050px) {
    justify-content: center;
  }
`

const ColWrapper = styled.div`
  display: flex;
  width: 100%;

  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: 1050px) {
    flex-direction: column;
  }
`

const Col = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  ${p =>
    !p.single &&
    css`
      &:first-of-type {
        padding-right: 120px;
      }
    `}
  @media (max-width: 1050px) {
    &:first-of-type {
      padding-right: 0px;
    }
  }
`

const ColText = styled(Text)``

const CheckMarkListWrapper = styled(Text)`
  li {
    color: ${p => (p.darkmode ? theme.color.white : theme.color.offBlack)};
    list-style-type: none;
    background-image: url(${p => (p.darkmode ? greenCheckMarkIcon : violetCheckMarkIcon)});
    background-repeat: no-repeat;
    padding-left: 30px;
    background-position: top 8px left;
  }

  @media (max-width: 1050px) {
    ul {
      width: fit-content;
      margin: 0 auto;
      text-align: left !important;
    }
    li {
      text-align: left !important;
    }
  }
`

const ColButtonWrapper = styled.div`
  display: flex;
  padding-top: 16px;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 32px;
  @media (max-width: 1050px) {
    padding-top: 16px;
  }
  @media (max-width: 800px) {
    width: 100%;
  }
`

const FlexibleText = ({ primary }) => {
  const centeredHeading = idx(primary, _ => _.centered_heading.richText[0].text) && (
    <RichText render={primary.centered_heading.richText} serializeHyperlink={GatsbyLink} />
  )
  const centerColText = idx(primary, _ => _.center_col_text.richText[0].text) && (
    <RichText render={primary.center_col_text.richText} serializeHyperlink={GatsbyLink} />
  )
  const leftHeading = idx(primary, _ => _.left_heading.richText[0].text) && (
    <RichText render={primary.left_heading.richText} serializeHyperlink={GatsbyLink} />
  )
  const rightHeading = idx(primary, _ => _.right_heading.richText[0].text) && (
    <RichText render={primary.right_heading.richText} serializeHyperlink={GatsbyLink} />
  )
  const leftColText = idx(primary, _ => _.left_col_text.richText[0]) && (
    <RichText render={primary.left_col_text.richText} serializeHyperlink={GatsbyLink} />
  )
  const rightColText = idx(primary, _ => _.right_col_text.richText[0]) && (
    <RichText render={primary.right_col_text.richText} serializeHyperlink={GatsbyLink} />
  )
  const leftColCheckMarkList = idx(primary, _ => _.left_col_checkmark_list.richText[0].text) && (
    <RichText render={primary.left_col_checkmark_list.richText} serializeHyperlink={GatsbyLink} />
  )
  const rightColCheckMarkList = idx(primary, _ => _.right_col_checkmark_list.richText[0].text) && (
    <RichText render={primary.right_col_checkmark_list.richText} serializeHyperlink={GatsbyLink} />
  )
  const rightColButtonText = idx(primary, _ => _.right_col_button_text.richText[0].text) && (
    <RichText render={primary.right_col_button_text.richText} serializeHyperlink={GatsbyLink} />
  )
  const leftColButtonText = idx(primary, _ => _.left_col_button_text.richText[0].text) && (
    <RichText render={primary.left_col_button_text.richText} serializeHyperlink={GatsbyLink} />
  )
  const leftColButtonUrl = idx(primary, _ => _.left_col_button_link.url)
  const rightColButtonUrl = idx(primary, _ => _.right_col_button_link.url)
  const buttonText = idx(primary, _ => _.button_text.text)
  const buttonUrl = idx(primary, _ => _.button_link.url)
  const backgroundColor = primary.background_color
  const anchorId = primary.anchor_id
  const darkmode = primary.background_color === 'purple900'
  const centerColTextLeftAligned = idx(primary, _ => _.center_col_text_left_aligned)
  const hasTopPadding = primary.has_top_padding === null ? true : primary.has_top_padding
  const hasBottomPadding = primary.has_bottom_padding === null ? true : primary.has_bottom_padding

  const headingOnly =
    centeredHeading &&
    !leftHeading &&
    !rightHeading &&
    !centerColText &&
    !leftColText &&
    !rightColText &&
    !leftColCheckMarkList &&
    !rightColCheckMarkList &&
    !rightColButtonText &&
    !leftColButtonText
  return (
    <Wrapper id={anchorId || null} hasTopPadding={hasTopPadding} hasBottomPadding={hasBottomPadding}>
      {(centeredHeading || centerColText) && (
        <CenteredWrapper centerColTextLeftAligned={centerColTextLeftAligned}>
          {centeredHeading && (
            <HeaderWrapper headingOnly={headingOnly}>
              <Text
                color={darkmode ? 'white' : 'offBlack'}
                darkmode
                center
                font={centeredHeading.props.render[0].type === 'heading1' ? 'heading1' : 'heading3'}
                richText
              >
                {centeredHeading}
              </Text>
            </HeaderWrapper>
          )}
          {centerColText && (
            <Col single>
              <ColText color={darkmode ? 'white' : 'offBlack'} center={!centerColTextLeftAligned} richText>
                {centerColText}
              </ColText>
            </Col>
          )}
        </CenteredWrapper>
      )}
      {(leftHeading ||
        leftColText ||
        rightHeading ||
        rightColText ||
        leftColCheckMarkList ||
        rightColCheckMarkList ||
        leftColButtonText ||
        rightColButtonText) && (
        <ColWrapper>
          <Col>
            {leftHeading && (
              <HeaderWrapper headingOnly={headingOnly}>
                <Text
                  color={darkmode ? 'white' : 'offBlack'}
                  font={leftHeading.props.render[0].type === 'heading1' ? 'heading1' : 'heading3'}
                  richText
                >
                  {leftHeading}
                </Text>
              </HeaderWrapper>
            )}
            {leftColText && (
              <ColText color={darkmode ? 'white' : 'offBlack'} richText>
                {leftColText}
              </ColText>
            )}
            {leftColCheckMarkList && (
              <CheckMarkListWrapper darkmode={darkmode} richText>
                {leftColCheckMarkList}
              </CheckMarkListWrapper>
            )}
            {leftColButtonText && leftColButtonUrl && (
              <ColButtonWrapper>
                <Button
                  buttonType={darkmode ? 'tertiary reversed' : 'primary'}
                  to={leftColButtonUrl}
                  text={leftColButtonText}
                />
              </ColButtonWrapper>
            )}
          </Col>
          <Space height={0} tabletHeight={16} />
          <Col>
            {rightHeading && (
              <HeaderWrapper headingOnly={headingOnly}>
                <Text
                  color={darkmode ? 'white' : 'offBlack'}
                  font={rightHeading.props.render[0].type === 'heading1' ? 'heading1' : 'heading3'}
                  richText
                >
                  {rightHeading}
                </Text>
              </HeaderWrapper>
            )}
            {!rightHeading && !centeredHeading && leftHeading && (
              <Space height={68} tabletHeight={8} style={{ marginBottom: '0px' }} />
            )}
            {rightColText && (
              <ColText color={darkmode ? 'white' : 'offBlack'} richText>
                {rightColText}
              </ColText>
            )}
            {rightColCheckMarkList && (
              <CheckMarkListWrapper darkmode={darkmode} richText>
                {rightColCheckMarkList}
              </CheckMarkListWrapper>
            )}
            {rightColButtonText && rightColButtonUrl && (
              <ColButtonWrapper>
                <Button
                  buttonType={darkmode ? 'tertiary reversed' : 'primary'}
                  to={rightColButtonUrl}
                  text={rightColButtonText}
                />
              </ColButtonWrapper>
            )}
          </Col>
        </ColWrapper>
      )}

      {buttonText && buttonUrl && (
        <ButtonWrapper>
          <Button buttonType={darkmode ? 'tertiary reversed' : 'primary'} to={buttonUrl} text={buttonText} />
        </ButtonWrapper>
      )}
      <BG
        className="bg"
        backgroundColor={backgroundColor}
        hasTopPadding={hasTopPadding}
        hasBottomPadding={hasBottomPadding}
      />
    </Wrapper>
  )
}

export default FlexibleText

export const query = graphql`
  fragment FlexibleText on PrismicPageDataBodyFlexibleText {
    ...SliceBase
    primary {
      background_color
      anchor_id
      has_top_padding
      has_bottom_padding
      button_text {
        ...TextContent
      }
      center_col_text {
        ...TextContent
      }
      center_col_text_left_aligned
      button_link {
        uid
        url
      }
      centered_heading {
        ...TextContent
      }
      left_col_text {
        ...TextContent
      }
      left_col_checkmark_list {
        ...TextContent
      }
      left_col_button_text {
        ...TextContent
      }
      left_col_button_link {
        uid
        url
      }
      left_heading {
        ...TextContent
      }
      right_col_text {
        ...TextContent
      }
      right_col_checkmark_list {
        ...TextContent
      }
      right_heading {
        ...TextContent
      }
      right_col_button_text {
        ...TextContent
      }
      right_col_button_link {
        uid
        url
      }
    }
  }
`
